import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  TrafficLayer,
  useJsApiLoader,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { db } from "../../firebase";
import { auth } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  Timestamp,
  doc,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import FloatingMapCard from "../FloatingMapCard/floatingMapCard";
import alarmSound from "./fire_emergency.mp3";
import callmarker from "./call.png";
import callmarkerfire from "./firepin.png";
import callmarkerems from "./emspin.png";
import ScrollingText from "../ScrollingText/ScrollingText";
const showStyle = {
  display: "block",
};
const hideStyle = {
  display: "none",
};

const FullScreenMap = ({ alarms, setAlarms, selectedTab, setSelectedTab }) => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [agency, setAgency] = useState(null);
  const [agencyLocation, setAgencyLocation] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [floatingMapCardText, setFloatingMapCardText] = useState(null);
  const [posts, setPosts] = useState([]);
  const [activePostIndex, setActivePostIndex] = useState(0);
  const [postText, setPostText] = useState("");
  //const [geocodingCache, setGeocodingCache] = useState({});
  const [currentMarkerIndex, setCurrentMarkerIndex] = useState(0);
  const [directions, setDirections] = useState(null);
  const [directionsEnabled, setDirectionsEnabled] = useState(false);
  const [delayedAudio, setDelayedAudio] = useState(false);
  const [hasCallins, setHasCallins] = useState(false);
  const [agencyLogoUrl, setAgencyLogoUrl] = useState("");

  const hasInvalidAddress = alarms.length > 0 && (!alarms[currentMarkerIndex]?.Address || alarms[currentMarkerIndex]?.Address.trim() === "");

  let geocodingCache = {};

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDEYcdHgZwlpq3QxIRqtT8G3NmodCSwhiM", 
    libraries: [], 
  });
  
  useEffect(() => {
    setDelayedAudio(true);

    const refreshInterval = setInterval(() => {
      setTimeout(() => {
        setDelayedAudio(false);
      }, 15000);

      window.location.reload();
    }, 60 * 60 * 1000); 

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  useEffect(() => {
    if (agency) {
      const fetchAgencySettings = async () => {
        const settingsCollectionRef = collection(db, agency + " Settings");
  
        onSnapshot(settingsCollectionRef, (querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => doc.data());
          if (data && data.length > 0) {
            setAgencyLogoUrl(data[0].logo);
          } else {
            console.error("No agency settings found");
          }
        });
      };
  
      fetchAgencySettings();
    }
  }, [agency]);

  useEffect(() => {
    if (agency) {
      const infoDocRef = doc(db, "Agencies", agency, "Settings", "Info");

getDoc(infoDocRef)
  .then((docSnapshot) => {
    if (docSnapshot.exists()) {
      const infoData = docSnapshot.data();
      setAgencyLocation({
        lat: parseFloat(infoData.lat),
        lng: parseFloat(infoData.long),
      });
      console.log('Fetched agencyLocation from database:', {
        lat: parseFloat(infoData.lat),
        lng: parseFloat(infoData.long),
      });
      console.log("Agency location fetched:", {
        lat: parseFloat(infoData.lat),
        lng: parseFloat(infoData.long),
      });
    } else {
      console.error("Info document does not exist");
    }
  })
  .catch((error) => {
    console.error("Error fetching agency info:", error);
  });

     
    }
  }, [agency]);

  useEffect(() => {
    if (agency) {
      
      const servicesDocRef = doc(db, "Agencies", agency, "Settings", "Services");
  
      getDoc(servicesDocRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const servicesData = docSnapshot.data();
            setDirectionsEnabled(servicesData.directions === true);
            console.log("Directions enabled:", servicesData.directions);
          } else {
            console.error("Services document does not exist");
          }
        })
        .catch((error) => {
          console.error("Error fetching services settings:", error);
        });
    }
  }, [agency]);

  
  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const unsubscribe = onSnapshot(userDocRef, (doc) => {
          if (!doc.exists()) {
          } else {
            const dataObj = doc.data();

            setAgency(dataObj.agency);

            if (dataObj.disabled) {
              // Replace this with your navigation logic
            }
          }
        });

        return () => {
          unsubscribe();
        };
      }
    });
    return () => {
      unsubscribeAuth();
    };
  }, []);

  useEffect(() => {
    if (agency) {
      const currentTime = new Date();
      const threeHoursAgo = new Date(currentTime.getTime() - 3 * 60 * 60 * 1000);
      const oneHourAgo = new Date(currentTime.getTime() - 60 * 60 * 1000);
      
      const threeHoursAgoTimestamp = Timestamp.fromDate(threeHoursAgo);
      const oneHourAgoTimestamp = Timestamp.fromDate(oneHourAgo);

      // Create a Firestore query for alarms
      const alarmsCollection = collection(db, agency + " Alarms");
      const alarmsQuery = query(
        alarmsCollection,
        where("TimeStamp", ">=", threeHoursAgoTimestamp),
      );

      const unsubscribeAlarms = onSnapshot(alarmsQuery, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const data = change.doc.data();
          if (change.type === "added" && !delayedAudio) {
            const audio = new Audio(alarmSound);
            audio.play();
          }
        });

        const filteredData = querySnapshot.docs
          .map(doc => {
            const data = doc.data();
            const docTimeStamp = data.TimeStamp.toDate();
            
            // Apply the same filtering logic as provided
            if (
              (data.hasOwnProperty('AlarmStatus') && data.AlarmStatus === 'true') || 
              (!data.hasOwnProperty('AlarmStatus') && docTimeStamp >= oneHourAgo) || 
              (data.callcard === '*DISPATCH*' && docTimeStamp >= oneHourAgo)
            ) {
              return { ...data, id: doc.id };
            }
            return null;
          })
          .filter(Boolean); // Remove null entries

        getMarkers(filteredData);
      });

      const deptCollection = collection(db, agency + " Station Board");
      const deptQuery = query(deptCollection);
      const unsubscribeDept = onSnapshot(deptQuery, (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        const apiKey = "AIzaSyDEYcdHgZwlpq3QxIRqtT8G3NmodCSwhiM";
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${data[0].zip}&key=${apiKey}`;
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "OK" && data.results.length > 0) {
              const location = data.results[0].geometry.location;
              const lat = location.lat;
              const lng = location.lng;

              setCurrentLocation({ lat, lng });
            } else {
              console.error("Geocoding failed for the provided zip code.");
            }
          });
      });

      // Create a Firestore query for the last 24 hours

      const postsCollection = collection(db, agency);
      const postsQuery = query(
        postsCollection,
        where("privacy", "in", ["All", "Stationboard"])
    );
      const unsubscribePosts = onSnapshot(postsQuery, (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        const postsWithSeconds = data.filter(
          (post) => post.postTime && post.postTime.seconds
        );

        postsWithSeconds.sort((a, b) => {
          return b.postTime.seconds - a.postTime.seconds;
        });
        let newPosts = postsWithSeconds.slice(0, 5);

        setPosts(newPosts);
      });

      return () => {
        unsubscribeAlarms();
        unsubscribeDept();
        unsubscribePosts();
      };
    }
  }, [agency]);

  async function getMarkers(data) {
    const updatedData = await Promise.all(
      data.map(async (alarm) => {
        // Parse latitude and longitude from Firestore data
        let latitude = parseFloat(alarm.Latitude);
        let longitude = parseFloat(alarm.Longitude);
  
        if (isNaN(latitude) || isNaN(longitude)) {
          // Coordinates are missing or invalid, proceed to geocode
          const address = alarm.Address.replace(/Address:/i, "").trim();
        const apiKey = "AIzaSyDEYcdHgZwlpq3QxIRqtT8G3NmodCSwhiM";
        
        // Log that we're about to geocode this address
        console.log(`Geocoding address for alarm ID ${alarm.id}: ${address}`);

        if (geocodingCache[address]) {
          // Use cached geocoding result
          const cachedResult = geocodingCache[address];
          latitude = cachedResult.lat;
          longitude = cachedResult.lng;
          console.log(`Using cached geocoding result for address: ${address}`);
        } else {
          // Call the geocoding API
          const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address + ", " + alarm.Town + " United States"
          )}&key=${apiKey}`;

          console.log(`Calling Geocoding API for address: ${address}`);
          
          const res = await fetch(apiUrl).then((response) => response.json());
          if (res.status === "OK" && res.results.length > 0) {
            const location = res.results[0].geometry.location;
            latitude = location.lat;
            longitude = location.lng;
            geocodingCache[address] = { lat: latitude, lng: longitude };
            console.log(`Geocoding successful for address: ${address}`);
          } else {
            console.error(`Geocoding request failed for address: ${address}, status: ${res.status}`);
          }
        }
      } else {
        // Coordinates are valid, use them
        console.log(`Using coordinates from Firestore for alarm ID ${alarm.id}: ${latitude}, ${longitude}`);
      }

      // Update the alarm object with the latitude and longitude
      alarm.lat = latitude;
      alarm.lng = longitude;

        const callinsRef = collection(db, `${agency} Alarms/${alarm.id}/Callins`);
        const callinsSnapshot = await getDocs(callinsRef);
        alarm.Callins = await Promise.all(
          callinsSnapshot.docs.map(async (callinDoc) => {
            const callinData = callinDoc.data();
            const qualificationsRef = collection(db, "Agencies", agency, "Qualifications");
            const qualificationsSnapshot = await getDocs(qualificationsRef);
            const userQualifications = [];

            qualificationsSnapshot.forEach((doc) => {
              const data = doc.data();
              if (data.users && data.users.includes(callinData.userId)) {
                userQualifications.push(doc.id);
              }
            });

            return {
              ...callinData,
              qualifications: userQualifications,
            };
          })
        );

        onSnapshot(callinsRef, (snapshot) => {
          const updatedCallins = snapshot.docs.map(async (callinDoc) => {
            const callinData = callinDoc.data();
            const qualificationsRef = collection(db, "Agencies", agency, "Qualifications");
            const qualificationsSnapshot = await getDocs(qualificationsRef);
            const userQualifications = [];

            qualificationsSnapshot.forEach((doc) => {
              const data = doc.data();
              if (data.users && data.users.includes(callinData.id)) {
                userQualifications.push(doc.id);
              }
            });

            return {
              ...callinData,
              qualifications: userQualifications,
            };
          });

          Promise.all(updatedCallins).then((resolvedCallins) => {
            alarm.Callins = resolvedCallins;
            setAlarms((prevAlarms) => {
              const updatedAlarms = prevAlarms.map((prevAlarm) =>
                prevAlarm.id === alarm.id ? { ...prevAlarm, Callins: alarm.Callins } : prevAlarm
              );
              return updatedAlarms;
            });
          });
        });

        return alarm;
      })
    );
    setAlarms(updatedData);
  }
  useEffect(() => {
    let currentIndex = -1;
  
    const timer = setInterval(async () => {
      if (alarms.length > 0) {
        await checkAlarms();
  
        // Calculate the index of the next active marker
        currentIndex = (currentIndex + 1) % alarms.length;
  
        const currentAlarm = alarms[currentIndex];
  
        // Set the active marker's position as the new current location
        setCurrentLocation(currentAlarm);
  
        // Update the floating map card text with the corresponding alarm
        setCurrentMarkerIndex(currentIndex);
        setFloatingMapCardText(currentAlarm);
  
        // **Conditionally Request Directions**
        if (!hasInvalidAddress && directionsEnabled && agencyLocation) {
          requestDirections(agencyLocation, currentAlarm);
        } else {
          // **Reset Directions if Not Applicable**
          setDirections(null);
        }
      } else {
        setFloatingMapCardText(null);
        setCurrentMarkerIndex(0);
        // **Reset Directions if No Alarms**
        setDirections(null);
      }
  
      if (alarms.length === 0) {
        geocodingCache = {};
        setSelectedTab("dashboard");
      }
    }, 12000); // Update every 12 seconds
  
    return () => {
      clearInterval(timer);
    };
  }, [alarms, hasInvalidAddress, directionsEnabled, agencyLocation]);
  const requestDirections = (origin, destination) => {
    console.log('Origin coordinates:', origin);
    if (origin && destination) {
      const directionsService = new window.google.maps.DirectionsService();
      console.log("Requesting directions service with origin:", origin, "destination:", destination);
      directionsService.route(
        {
          origin: { lat: origin.lat, lng: origin.lng },
          destination: { lat: destination.lat, lng: destination.lng },
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
            console.log("Directions fetched successfully:", result);
          } else {
            console.error(`Error fetching directions: ${status}`, result);
          }
        }
      );
    }
  };
  

  useEffect(() => {
    getPosts();
    const timer = setInterval(async () => {
      getPosts();
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, [posts]);

  async function getPosts() {
    let currentIndex = -1;
    if (posts.length > 0) {
      currentIndex = (currentIndex + 1) % posts.length;
      setActivePostIndex(currentIndex);

      const documentSnapshot = await getDoc(
        doc(db, "users", posts[currentIndex].userId)
      );

      setPostText(
        posts[currentIndex].post + " -- " + documentSnapshot.data().name
      );
    } else {
      setPostText("");
      setActivePostIndex(-1);
    }
  }

  function checkAlarms() {
    return new Promise((resolve, reject) => {
      var newAlarms = alarms;
      for (let i = 0; i < newAlarms.length; i++) {
        let marker = newAlarms[i];
        let time = marker.TimeStamp;
        let date = time.toDate();
        let now = new Date();

        const timeDifference = Math.abs(date - now);

        // Calculate the number of milliseconds in an hour
        const millisecondsInThreeHours = 3 * 60 * 60 * 1000;
        if (timeDifference > millisecondsInThreeHours) {
          newAlarms.splice(i, 1);
        }
      }
      setAlarms(newAlarms);
      resolve();
    });
  }
  const containerStyle = {
    width: "100vw",
    height: "100vh",
    display: selectedTab == "map" ? "block" : "none",
  };
  

  const mapOptions = {
    mapTypeId: "hybrid",
    trafficLayer: true,
  };

  const getMarkerIcon = (callcard) => {
    switch (callcard) {
      case "FIRE":
        return callmarkerfire;
      case "EMS":
        return callmarkerems;
      default:
        return callmarker;
    }
  };


  if (!isLoaded) {
    return <div>Loading Map...</div>;
  }

  if (loadError) {
    return <div>Error loading maps</div>;
  }
  

  return (
    <div style={{ position: "relative" }}>
      { !hasInvalidAddress && alarms.length > 0 && currentLocation ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={currentLocation}
          zoom={20}
          options={mapOptions}
        >
          <TrafficLayer autoRefresh />
          {alarms.map((alarm, index) => (
            <Marker
              key={alarm.id}
              position={{ lat: alarm.lat, lng: alarm.lng }}
              title={`Alarm ${index + 1}`}
              icon={{
                url: getMarkerIcon(alarm.callcard),
                scaledSize: new window.google.maps.Size(50, 50),
              }}
            />
          ))}
          {directions && (
    <DirectionsRenderer
      directions={directions}
      options={{
        polylineOptions: {
          strokeColor: "#FF0000",
          strokeWeight: 5,
        },
        suppressMarkers: false,
      }}
    />
  )}

        </GoogleMap>
      ) : hasInvalidAddress ? (
        // Render the background with agency logo and message
        <div
          style={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "#42474a",
            display: selectedTab === "map" ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          {agencyLogoUrl ? (
            <>
              <img
                src={agencyLogoUrl}
                alt="Agency Logo"
                style={{
                  maxWidth: "80%",
                  maxHeight: "80%",
                  marginBottom: "20px",
                }}
              />
              <p style={{
                color: "white",
                fontSize: "20px",
                textAlign: "center",
                maxWidth: "80%",
              }}>
                Mapping is not available for this alarm
              </p>
            </>
          ) : (
            <p style={{ color: "white" }}>Loading agency logo...</p>
          )}
        </div>
      ) : (
        <div style={{ 
          width: "100vw", 
          height: "100vh", 
          backgroundColor: "#42474a",
          display: selectedTab === "map" ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <p style={{ color: "white" }}>Loading map...</p>
        </div>
      )}
      <FloatingMapCard
        text={floatingMapCardText}
        onCallinsChange={setHasCallins}
        directionsEnabled={directionsEnabled && !hasInvalidAddress} // Ensure directions are disabled when there's an invalid address
        directions={directions}
        isFullscreenMapVisible={selectedTab === "map"}
      />
    </div>
  );
  
  
};

export default FullScreenMap;