import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const NexradWeatherMap = () => {
  const mapRef = useRef(null);
  const [weatherAlerts, setWeatherAlerts] = useState([]);

  useEffect(() => {
    if (mapRef.current) {
      // Initialize the map for Long Island area
      const map = L.map(mapRef.current).setView([40.789142, -73.13496], 8);

      // Add a tile layer (using OpenStreetMap as a base layer)
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

      // Add NEXRAD radar data overlay from NOAA (example WMS layer) with looping enabled
      const nexradLayer = L.tileLayer.wms('https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r.cgi', {
        layers: 'nexrad-n0r-900913',
        format: 'image/png',
        transparent: true,
        attribution: 'Ladder Suite WX API v1.0',
        time: 'P0D', 
        animate: true
      });

      nexradLayer.addTo(map);

      // Set an interval to refresh the radar layer to simulate looping
      const intervalId = setInterval(() => {
        nexradLayer.setParams({ time: Date.now() });
      }, 60000); 

      // Add weather alerts overlay from NOAA
      const weatherAlertsLayer = L.tileLayer.wms('https://opengeo.ncep.noaa.gov/geoserver/ows?', {
        layers: 'nws_alerts',
        format: 'image/png',
        transparent: true,
        
      });

      weatherAlertsLayer.addTo(map);

      // Add storm tracks overlay from NOAA
      const stormTracksLayer = L.tileLayer.wms('https://opengeo.ncep.noaa.gov/geoserver/ows?', {
        layers: 'nws_storm_tracks',
        format: 'image/png',
        transparent: true,
       
      });

      stormTracksLayer.addTo(map);

      // Fetch weather alerts as GeoJSON for zipcode 11751 and update state to display as a list
      const fetchWeatherAlerts = async () => {
        try {
          const response = await fetch('https://api.weather.gov/alerts/active?point=40.7298,-73.1926');
          const data = await response.json();
          if (data.features) {
            setWeatherAlerts(data.features);
          }
        } catch (error) {
          console.error('Error fetching weather alerts:', error);
        }
      };

      fetchWeatherAlerts();

      return () => {
        map.remove();
        clearInterval(intervalId);
      };
    }
  }, []);

  return (
    <div>
      <div ref={mapRef} className="nexradMap" style={{ height: '450px', width: '100%' }}></div>
      <div className="weatherAlerts">
        <h3>Weather Alerts</h3>
        <ul>
          {weatherAlerts.length > 0 ? (
            weatherAlerts.map((alert, index) => (
              <li key={index}>
                <strong>{alert.properties.event}</strong>: {alert.properties.headline}
              </li>
            ))
          ) : (
            <li>No active weather alerts.</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default NexradWeatherMap;
