import React, {useEffect} from "react";


const FloatingMapCard = ({ text, directionsEnabled, directions, onCallinsChange, isFullscreenMapVisible, }) => {
  const defaultImgUrl = "https://firebasestorage.googleapis.com/v0/b/ladderemsrn.appspot.com/o/photos%2Favatar.png?alt=media&token=8e349a8b-01d2-4e6b-bc9c-fc23482d2230&_gl=1*elqacr*_ga*OTczNjc0NTc5LjE2OTYzNjk8e349a8b-01d2-4e6b-bc9c-fc23482d2230&_gl=1*elqacr*_ga*OTczNjc0NTc5LjE2OTYzNjk";
  
  const hasActiveCall = text && Object.keys(text).length > 0;

  const tableHeaderStyle = {
    border: "1px solid white",
    padding: "4px", // Reduced padding
    textAlign: "left",
    backgroundColor: "#2a4b6e",
    fontSize: "12px", // Ensure consistent text size
  };
  
  const tableCellStyle = {
    border: "1px solid white",
    padding: "4px", // Reduced padding
    fontSize: "12px", // Ensure consistent text size
  };




  let cardTextElement = null;
  let fresCommentsElement = null;
  let callinsElement = null;
  let directionsElement = null;

  if (text) {
    cardTextElement = (
      <div
        style={{
          color: "white",
          backgroundColor: "#1f3652",
          padding: "15px",
          borderRadius: "0 0 8px 8px",
          textAlign: "left",
        }}
      >
        <p style={{ fontSize: "20px",  }}>{text.CallType}</p>
        <p style={{ fontSize: "20px", color: "yellow" }}>{text.DispatchCode}</p>
        <br />
        <p style={{ fontSize: "20px" }}>{text.Address}</p>
        <p style={{ fontSize: "20px" }}>{text.CrossStreet}</p>
        <br />
        <p style={{ fontSize: "18px" }}>**{text.FRESComments}**</p>
        <br />
        <p style={{ fontSize: "18px" }}>{text.EventComments}</p>
      </div>
    );

    
    if (text.Callins && text.Callins.length > 0) {
      callinsElement = (
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            zIndex: 1000,
            background: "#1f3652", // Changed to match the theme
            borderRadius: "0 0 0 8px",
            boxShadow: "-2px 0px 5px rgba(0, 0, 0, 0.3)",
            width: "350px",
            height: "100vh",
            overflowY: "auto",
          }}
        >
          {/* Header */}
          <div
            style={{
              backgroundColor: "white",
              color: "black",
              padding: "10px",
              borderRadius: "0 0 0 8px",
              textAlign: "left",
              fontWeight: "bold",
              position: "sticky",
              top: "0",
              zIndex: 1,
            }}
          >
            Call-ins:
          </div>
          {/* Table Content */}
          <div
            style={{
              padding: "10px",
              textAlign: "left",
            }}
          >
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                color: "white",
                fontSize: "12px", // Reduced text size
              }}
            >
              <thead>
                <tr>
                  <th style={tableHeaderStyle}>Name</th>
                  <th style={tableHeaderStyle}>Response</th>
                  <th style={tableHeaderStyle}>Called In</th>
                  <th style={tableHeaderStyle}>Qualifications</th>
                </tr>
              </thead>
              <tbody>
                {text.Callins.map((callin, index) => (
                  <tr key={index}>
                    <td style={tableCellStyle}>{callin.name}</td>
                    <td style={tableCellStyle}>{callin.response}</td>
                    <td style={tableCellStyle}>
                      {new Date(callin.timestamp.seconds * 1000).toLocaleTimeString()}
                    </td>
                    <td style={tableCellStyle}>
                      {(callin.qualifications || []).join(", ")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  } else if (isFullscreenMapVisible) {
    
    cardTextElement = (
      <div
        style={{
          color: "white",
          backgroundColor: "#111921",
          padding: "15px",
          borderRadius: "0 0 8px 8px",
          textAlign: "left",
        }}
      >
        <p>Stand By...Loading Call Data...</p>
      </div>
    );
  }
  if (directionsEnabled && directions) {
    directionsElement = (
      <div
        style={{
          position: "absolute",
          top: "2%",
          right: text?.Callins && text.Callins.length > 0 ? "360px" : "300px",
          zIndex: 1000,
          background: "red",
          borderRadius: "8px",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
          maxWidth: "425px",
          overflowY: "auto",
        }}
      >
        {/* Header */}
        <div
          style={{
            backgroundColor: "white",
            color: "black",
            padding: "10px",
            borderRadius: "8px 8px 0 0",
            marginBottom: "10px",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          Directions:
        </div>
        {/* Content */}
        <div
          style={{
            color: "white",
            backgroundColor: "#1f3652",
            padding: "15px",
            borderRadius: "0 0 8px 8px",
            textAlign: "left",
            maxHeight: "60vh",
            overflowY: "auto",
          }}
        >
          <ol style={{ paddingLeft: "20px" }}>
            {directions.routes[0].legs[0].steps.map((step, index) => (
              <li
                key={index}
                style={{ marginBottom: "10px", fontSize: "12px" }}
                dangerouslySetInnerHTML={{ __html: step.instructions }}
              />
            ))}
          </ol>
        </div>
      </div>
    );
  }
 
  useEffect(() => {
    if (onCallinsChange) {
      onCallinsChange(!!(text && text.Callins && text.Callins.length > 0));
    }
  }, [text]);

  return (
    <div>
      {(hasActiveCall || isFullscreenMapVisible) && (
        <div
          style={{
            position: "absolute",
            top: "2%",
            left: "10px",
            zIndex: 1000,
            background: "red",
            borderRadius: "8px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
            maxWidth: "425px",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              color: "black",
              padding: "10px",
              borderRadius: "8px 8px 0 0",
              marginBottom: "10px",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Alarm Details:<br />
          </div>
          {cardTextElement}
        </div>
      )}
      {callinsElement}
      {directionsElement}
    </div>
  );
};

export default FloatingMapCard;
